import React from "react";
import styles from "./home-form.module.scss";
import { Session, SessionBody, SessionHeader } from "../../modules/session";
import Image from "./assets/soc.png";
import cn from "classnames";
import config from "./config";
import validate from "validate.js";
import Checkbox from "@material-ui/core/Checkbox";

function HomeForm() {
  const [errors, setErrors] = React.useState(false);

  const [loading, setLoading] = React.useState(false);
  const [fieldsState, setFieldsState] = React.useState(config.fieldsState);
  const refForm = React.useRef(null);
  function handleChange(name, text) {
    setFieldsState((prevState) => ({ ...prevState, [name]: text }));
    setErrors(false);
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    let isValid = true;
    setLoading(true);

    await validate.async(fieldsState, config.constraints).then(null, (err) => {
      setErrors(err);
      isValid = false;
    });
    if (!isValid) return setLoading(false);
    refForm.current.submit();
  };
  return (
    <div id="white-paper">
      <Session className={styles.session}>
        <SessionBody className={styles.session__form}>
          <div className={styles.session__form__left}>
            <h3>
              Guia Executivo para a aquisição de serviços Security Operations
              Center (SOC)
            </h3>
            <h2>Security Operations Center (SOC)</h2>
            <img src={Image} />
          </div>

          <div className={styles.session__form__form}>
            <h3>
              Preencha o formulário e descarregue já o White Paper completo
            </h3>
            <form
              ref={refForm}
              onSubmit={handleSubmit}
              method="post"
              id="easyform_2eue24i2efUVaoCC0Ze50f6d663"
              class="easyform_2eue24i2efUVaoCC0Ze50f6d663"
              enctype="multipart/form-data"
              action="https://31.e-goi.com//w/2eue24i2efUVaoCC0Ze50f6d663"
            >
              <input type="hidden" name="lista" value="2" />
              <input type="hidden" name="cliente" value="447422" />
              <input type="hidden" name="lang" id="lang_id" value="pt" />
              <input type="hidden" name="formid" id="formid" value="29" />

              <div className={styles.formControlRowCol2}>
                <div className={styles.formControlRow}>
                  <div className={styles.formControl}>
                    <div className={styles.formControlSpan}>
                      NOME <span>*</span>
                    </div>
                    <div
                      className={
                        errors["fname_406"]
                          ? styles.formControlInputErr
                          : styles.formControlInput
                      }
                    >
                      <input
                        name="fname_406"
                        type="text"
                        value={fieldsState.fname_406}
                        onChange={(event) =>
                          handleChange("fname_406", event.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.formControlRow}>
                  <div className={styles.formControl}>
                    <div className={styles.formControlSpan}>
                      APELIDO <span>*</span>
                    </div>
                    <div
                      className={
                        errors["lname_407"]
                          ? styles.formControlInputErr
                          : styles.formControlInput
                      }
                    >
                      <input
                        name="lname_407"
                        type="text"
                        value={fieldsState.lname_407}
                        onChange={(event) =>
                          handleChange("lname_407", event.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.formControlRow}>
                <div className={styles.formControl}>
                  <div className={styles.formControlSpan}>
                    EMAIL <span>*</span>
                  </div>
                  <div
                    className={
                      errors["email_410"]
                        ? styles.formControlInputErr
                        : styles.formControlInput
                    }
                  >
                    <input
                      name="email_410"
                      type="text"
                      value={fieldsState.email_410}
                      onChange={(event) =>
                        handleChange("email_410", event.target.value)
                      }
                    />
                  </div>
                </div>
              </div>
              <div className={styles.formControlRow}>
                <div className={cn(styles.formControl)}>
                  <div className={styles.formControlSpan}>
                    TELEFONE<span>*</span>
                  </div>
                  <div className={cn(styles.formControlCol3)}>
                    <div
                      className={
                        errors["ind_telemovel_405"]
                          ? styles.formControlSelectErr
                          : styles.formControlSelect
                      }
                    >
                      <select
                        name="ind_telemovel_405"
                        value={fieldsState.ind_telemovel_405}
                        onChange={(event) =>
                          handleChange("ind_telemovel_405", event.target.value)
                        }
                      >
                        <option value="+93">Afeganist&#227;o (+93)</option>
                        <option value="+27">&#193;frica do Sul (+27)</option>
                        <option value="+355">Alb&#226;nia (+355)</option>
                        <option value="+49">Alemanha (+49)</option>
                        <option value="+213">Alg&#233;ria (+213)</option>
                        <option value="+376">Andorra (+376)</option>
                        <option value="+244">Angola (+244)</option>
                        <option value="+12684">Anguilla (+12684)</option>
                        <option value="+1268">
                          Ant&#237;gua e Barbuda (+1268)
                        </option>
                        <option value="+966">Ar&#225;bia Saudita (+966)</option>
                        <option value="+54">Argentina (+54)</option>
                        <option value="+374">Arm&#233;nia (+374)</option>
                        <option value="+297">Aruba (+297)</option>
                        <option value="+247">Ascension (+247)</option>
                        <option value="+61">Austr&#225;lia (+61)</option>
                        <option value="+43">&#193;ustria (+43)</option>
                        <option value="+994">Azerbeij&#227;o (+994)</option>
                        <option value="+1242">Baamas (+1242)</option>
                        <option value="+973">Bahrein (+973)</option>
                        <option value="+880">Bangladesh (+880)</option>
                        <option value="+1246">Barbados (+1246)</option>
                        <option value="+32">B&#233;lgica (+32)</option>
                        <option value="+501">Belize (+501)</option>
                        <option value="+229">Benin (+229)</option>
                        <option value="+1441">Bermuda (+1441)</option>
                        <option value="+375">Bielorr&#250;ssia (+375)</option>
                        <option value="+591">Bol&#237;via (+591)</option>
                        <option value="+599">
                          Bonaire, Saint Eustatius e Saba, Cura&#231;&#227;o e
                          Sint Maarten Holand&#234;s (+599)
                        </option>
                        <option value="+387">
                          B&#243;snia-Herzegovina (+387)
                        </option>
                        <option value="+267">Botsuana (+267)</option>
                        <option value="+55">Brasil (+55)</option>
                        <option value="+673">Brunei (+673)</option>
                        <option value="+359">Bulg&#225;ria (+359)</option>
                        <option value="+226">Burkina-Faso (+226)</option>
                        <option value="+257">Burundi (+257)</option>
                        <option value="+975">But&#227;o (+975)</option>
                        <option value="+238">Cabo Verde (+238)</option>
                        <option value="+237">Camar&#245;es (+237)</option>
                        <option value="+855">Camboja (+855)</option>
                        <option value="+1">
                          Canad&#225;, EUA, Puerto Rico e Rep&#250;blica
                          Dominicana (+1)
                        </option>
                        <option value="+7">
                          Cazaquist&#227;o, Quirguist&#227;o e R&#250;ssia (+7)
                        </option>
                        <option value="+235">Chade (+235)</option>
                        <option value="+56">Chile (+56)</option>
                        <option value="+86">China (+86)</option>
                        <option value="+357">Chipre (+357)</option>
                        <option value="+379">Cidade do Vaticano (+379)</option>
                        <option value="+57">Col&#244;mbia (+57)</option>
                        <option value="+269">Comores (+269)</option>
                        <option value="+243">Congo-Brazzaville (+243)</option>
                        <option value="+242">Congo-Kinshasa (+242)</option>
                        <option value="+850">Coreia do Norte (+850)</option>
                        <option value="+82">Coreia do Sul (+82)</option>
                        <option value="+225">Costa do Marfim (+225)</option>
                        <option value="+506">Costa Rica (+506)</option>
                        <option value="+385">Cro&#225;cia (+385)</option>
                        <option value="+53">Cuba (+53)</option>
                        <option value="+246">Diego Garcia (+246)</option>
                        <option value="+45">Dinamarca (+45)</option>
                        <option value="+1767">Dominica (+1767)</option>
                        <option value="+20">Egipto (+20)</option>
                        <option value="+503">El Salvador (+503)</option>
                        <option value="+971">
                          Emirados &#193;rabes Unidos (+971)
                        </option>
                        <option value="+593">Equador (+593)</option>
                        <option value="+291">Eritreia (+291)</option>
                        <option value="+421">Eslov&#225;quia (+421)</option>
                        <option value="+386">Eslov&#233;nia (+386)</option>
                        <option value="+34">Espanha (+34)</option>
                        <option value="+372">Est&#243;nia (+372)</option>
                        <option value="+251">Eti&#243;pia (+251)</option>
                        <option value="+679">Fiji (+679)</option>
                        <option value="+63">Filipinas (+63)</option>
                        <option value="+358">Finl&#226;ndia (+358)</option>
                        <option value="+33">Fran&#231;a (+33)</option>
                        <option value="+241">Gab&#227;o (+241)</option>
                        <option value="+220">G&#226;mbia (+220)</option>
                        <option value="+233">Gana (+233)</option>
                        <option value="+995">Ge&#243;rgia (+995)</option>
                        <option value="+350">Gibraltar (+350)</option>
                        <option value="+1473">Granada (+1473)</option>
                        <option value="+30">Gr&#233;cia (+30)</option>
                        <option value="+299">Gronel&#226;ndia (+299)</option>
                        <option value="+590">Guadeloupe (+590)</option>
                        <option value="+1671">Guam (+1671)</option>
                        <option value="+502">Guatemala (+502)</option>
                        <option value="+592">Guiana (+592)</option>
                        <option value="+594">
                          Guiana Franc&#234;sa (+594)
                        </option>
                        <option value="+224">Guin&#233; (+224)</option>
                        <option value="+240">
                          Guin&#233; Equatorial (+240)
                        </option>
                        <option value="+245">Guin&#233;-Bissau (+245)</option>
                        <option value="+509">Haiti (+509)</option>
                        <option value="+504">Honduras (+504)</option>
                        <option value="+852">Hong Kong (+852)</option>
                        <option value="+36">Hungria (+36)</option>
                        <option value="+967">I&#233;men (+967)</option>
                        <option value="+44">
                          Ilha de Man e Reino Unido (+44)
                        </option>
                        <option value="+1345">Ilhas Cayman (+1345)</option>
                        <option value="+682">Ilhas Cook (+682)</option>
                        <option value="+500">Ilhas Falkland (+500)</option>
                        <option value="+298">Ilhas Faroe (+298)</option>
                        <option value="+1670">
                          Ilhas Marianas do Norte (+1670)
                        </option>
                        <option value="+692">Ilhas Marshall (+692)</option>
                        <option value="+677">Ilhas Salom&#227;o (+677)</option>
                        <option value="+1340">
                          Ilhas Virgem Americas (+1340)
                        </option>
                        <option value="+1284">
                          Ilhas Virgens Brit&#226;nicas (+1284)
                        </option>
                        <option value="+91">&#205;ndia (+91)</option>
                        <option value="+62">Indon&#233;sia (+62)</option>
                        <option value="+98">Ir&#227;o (+98)</option>
                        <option value="+964">Iraque (+964)</option>
                        <option value="+353">Irlanda (+353)</option>
                        <option value="+354">Isl&#226;ndia (+354)</option>
                        <option value="+972">Israel (+972)</option>
                        <option value="+39">It&#225;lia (+39)</option>
                        <option value="+1876">Jamaica (+1876)</option>
                        <option value="+81">Jap&#227;o (+81)</option>
                        <option value="+253">Jibuti (+253)</option>
                        <option value="+962">Jord&#226;nia (+962)</option>
                        <option value="+965">Koweit (+965)</option>
                        <option value="+856">Laos (+856)</option>
                        <option value="+266">Lesoto (+266)</option>
                        <option value="+371">Let&#243;nia (+371)</option>
                        <option value="+961">L&#237;bano (+961)</option>
                        <option value="+231">Lib&#233;ria (+231)</option>
                        <option value="+218">L&#237;bia (+218)</option>
                        <option value="+423">Liechtenstein (+423)</option>
                        <option value="+370">Litu&#226;nia (+370)</option>
                        <option value="+352">Luxemburgo (+352)</option>
                        <option value="+853">Macau (+853)</option>
                        <option value="+389">Maced&#243;nia (+389)</option>
                        <option value="+261">Madag&#225;scar (+261)</option>
                        <option value="+60">Mal&#225;sia (+60)</option>
                        <option value="+265">Malawi (+265)</option>
                        <option value="+960">Maldivas (+960)</option>
                        <option value="+223">Mali (+223)</option>
                        <option value="+356">Malta (+356)</option>
                        <option value="+212">Marrocos (+212)</option>
                        <option value="+596">Martinica (+596)</option>
                        <option value="+230">Maur&#237;cia (+230)</option>
                        <option value="+222">Maurit&#226;nia (+222)</option>
                        <option value="+262">Mayotte e Reunion (+262)</option>
                        <option value="+52">M&#233;xico (+52)</option>
                        <option value="+95">Mianmar (+95)</option>
                        <option value="+691">Micron&#233;sia (+691)</option>
                        <option value="+258">Mo&#231;ambique (+258)</option>
                        <option value="+373">Mold&#225;via (+373)</option>
                        <option value="+377">M&#243;naco (+377)</option>
                        <option value="+976">Mong&#243;lia (+976)</option>
                        <option value="+382">Montenegro (+382)</option>
                        <option value="+1664">Montserrat (+1664)</option>
                        <option value="+264">Nam&#237;bia (+264)</option>
                        <option value="+674">Nauru (+674)</option>
                        <option value="+977">Nepal (+977)</option>
                        <option value="+505">Nicar&#225;gua (+505)</option>
                        <option value="+227">N&#237;ger (+227)</option>
                        <option value="+234">Nig&#233;ria (+234)</option>
                        <option value="+683">Niue (+683)</option>
                        <option value="+47">Noruega (+47)</option>
                        <option value="+687">Nova Caledonia (+687)</option>
                        <option value="+64">Nova Zel&#226;ndia (+64)</option>
                        <option value="+968">Om&#227; (+968)</option>
                        <option value="+31">Pa&#237;ses Baixos (+31)</option>
                        <option value="+680">Palau (+680)</option>
                        <option value="+970">Palestina (+970)</option>
                        <option value="+507">Panam&#225; (+507)</option>
                        <option value="+675">
                          Papua Nova Guin&#233; (+675)
                        </option>
                        <option value="+92">Paquist&#227;o (+92)</option>
                        <option value="+595">Paraguai (+595)</option>
                        <option value="+51">Peru (+51)</option>
                        <option value="+689">
                          Polin&#233;sia Franc&#234;sa (+689)
                        </option>
                        <option value="+48">Pol&#243;nia (+48)</option>
                        <option selected={true} value="+351">
                          Portugal (+351)
                        </option>
                        <option value="+974">Qatar (+974)</option>
                        <option value="+254">Qu&#233;nia (+254)</option>
                        <option value="+686">Quiribati (+686)</option>
                        <option value="+236">
                          Rep&#250;blica Centro-Africana (+236)
                        </option>
                        <option value="+420">
                          Rep&#250;blica Checa (+420)
                        </option>
                        <option value="+40">Rom&#233;nia (+40)</option>
                        <option value="+250">Ruanda (+250)</option>
                        <option value="+290">
                          Saint Helena, Tristan da Cunha (+290)
                        </option>
                        <option value="+1869">
                          Saint Kitts e Nevis (+1869)
                        </option>
                        <option value="+508">
                          Saint Pierre and Miquelon (+508)
                        </option>
                        <option value="+685">Samoa (+685)</option>
                        <option value="+1684">Samoa Americana (+1684)</option>
                        <option value="+1758">Santa L&#250;cia (+1758)</option>
                        <option value="+378">S&#227;o Marino (+378)</option>
                        <option value="+239">
                          S&#227;o Tom&#233; e Pr&#237;ncipe (+239)
                        </option>
                        <option value="+1784">
                          S&#227;o Vicente e Granadinas (+1784)
                        </option>
                        <option value="+248">Seicheles (+248)</option>
                        <option value="+221">Senegal (+221)</option>
                        <option value="+232">Serra Leoa (+232)</option>
                        <option value="+381">S&#233;rvia (+381)</option>
                        <option value="+65">Singapura (+65)</option>
                        <option value="+963">S&#237;ria (+963)</option>
                        <option value="+252">Som&#225;lia (+252)</option>
                        <option value="+94">Sri Lanka (+94)</option>
                        <option value="+268">Suazil&#226;ndia (+268)</option>
                        <option value="+249">Sud&#227;o (+249)</option>
                        <option value="+46">Su&#233;cia (+46)</option>
                        <option value="+41">Su&#237;&#231;a (+41)</option>
                        <option value="+597">Suriname (+597)</option>
                        <option value="+66">Tail&#226;ndia (+66)</option>
                        <option value="+886">Taiwan (+886)</option>
                        <option value="+992">Tajiquist&#227;o (+992)</option>
                        <option value="+255">Tanz&#226;nia (+255)</option>
                        <option value="+670">Timor-Leste (+670)</option>
                        <option value="+228">Togo (+228)</option>
                        <option value="+676">Tonga (+676)</option>
                        <option value="+1868">Trindade e Tobago (+1868)</option>
                        <option value="+216">Tun&#237;sia (+216)</option>
                        <option value="+1649">
                          Turks and Caicos Islands (+1649)
                        </option>
                        <option value="+993">Turquemenist&#227;o (+993)</option>
                        <option value="+90">Turquia (+90)</option>
                        <option value="+688">Tuvalu (+688)</option>
                        <option value="+380">Ucr&#226;nia (+380)</option>
                        <option value="+256">Uganda (+256)</option>
                        <option value="+598">Uruguai (+598)</option>
                        <option value="+998">Usbequist&#227;o (+998)</option>
                        <option value="+678">Vanuatu (+678)</option>
                        <option value="+58">Venezuela (+58)</option>
                        <option value="+84">Vietname (+84)</option>
                        <option value="+681">Wallis and Futuna (+681)</option>
                        <option value="+260">Z&#226;mbia (+260)</option>
                        <option value="+263">Zimbabwe (+263)</option>
                      </select>
                    </div>
                    <div className={styles.extra}>
                      <div
                        className={
                          errors["telemovel_405_numero"]
                            ? styles.formControlInputErr
                            : styles.formControlInput
                        }
                      >
                        <input
                          name="telemovel_405_numero"
                          type="text"
                          value={fieldsState.telemovel_405_numero}
                          onChange={(event) =>
                            handleChange(
                              "telemovel_405_numero",
                              event.target.value
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.formControlRow}>
                <div className={styles.formControlRowCol2}>
                  <div className={styles.formControlRow}>
                    <div className={styles.formControl}>
                      <div className={styles.formControlSpan}>
                        CARGO
                        <span>*</span>
                      </div>
                      <div
                        className={
                          errors["easygoi_408"]
                            ? styles.formControlInputErr
                            : styles.formControlInput
                        }
                      >
                        <input
                          name="easygoi_408"
                          type="text"
                          value={fieldsState.easygoi_408}
                          onChange={(event) =>
                            handleChange("easygoi_408", event.target.value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className={styles.formControlRow}>
                    <div className={styles.formControl}>
                      <div className={styles.formControlSpan}>
                        EMPRESA
                        <span>*</span>
                      </div>
                      <div
                        className={
                          errors["easygoi_409"]
                            ? styles.formControlInputErr
                            : styles.formControlInput
                        }
                      >
                        <input
                          name="easygoi_409"
                          type="text"
                          value={fieldsState.easygoi_409}
                          onChange={(event) =>
                            handleChange("easygoi_409", event.target.value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.formControlRow}>
                <div className={styles.formControl}>
                  <div className={styles.formControlSpan}>
                    Indústria
                    <span>*</span>
                  </div>
                  <div
                    className={
                      errors["campoe_18_411"]
                        ? styles.formControlSelectErr
                        : styles.formControlSelect
                    }
                  >
                    <select
                      name="campoe_18_411"
                      value={fieldsState.campoe_18_411}
                      onChange={(event) =>
                        handleChange("campoe_18_411", event.target.value)
                      }
                    >
                      <option value=""></option>
                      <option value="1" title="Construction" order="0">
                        Construction
                      </option>
                      <option value="2" title="Banking" order="1">
                        Banking
                      </option>
                      <option value="3" title="Consumer" order="2">
                        Consumer
                      </option>
                      <option value="4" title="Education" order="3">
                        Education
                      </option>
                      <option
                        value="5"
                        title="Federal/central government"
                        order="4"
                      >
                        Federal/central government
                      </option>
                      <option value="6" title="Healthcare provider" order="5">
                        Healthcare provider
                      </option>
                      <option value="7" title="Insurance" order="6">
                        Insurance
                      </option>
                      <option
                        value="8"
                        title="Information Technology (SW, HW, Services)"
                        order="7"
                      >
                        Information Technology (SW, HW, Services)
                      </option>
                      <option value="9" title="Manufacturing" order="8">
                        Manufacturing
                      </option>
                      <option value="10" title="Media" order="9">
                        Media
                      </option>
                      <option
                        value="11"
                        title="Personal and consumer services"
                        order="10"
                      >
                        Personal and consumer services
                      </option>
                      <option
                        value="12"
                        title="Professional Services"
                        order="11"
                      >
                        Professional Services
                      </option>
                      <option value="13" title="Retail" order="12">
                        Retail
                      </option>
                      <option
                        value="14"
                        title="Securities and investment services"
                        order="13"
                      >
                        Securities and investment services
                      </option>
                      <option
                        value="15"
                        title="State/local government "
                        order="14"
                      >
                        State/local government
                      </option>
                      <option value="16" title="Transportation" order="15">
                        Transportation
                      </option>
                      <option value="17" title="Utilities" order="16">
                        Utilities
                      </option>
                      <option value="18" title="Wholesale " order="17">
                        Wholesale
                      </option>
                      <option value="19" title="Telecommunications" order="18">
                        Telecommunications
                      </option>
                      <option
                        value="20"
                        title="Hospitality, Dining, &amp; Travel"
                        order="19"
                      >
                        Hospitality, Dining, &amp; Travel
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div className={styles.formControlRow}>
                <div className={styles.formControl}>
                  <div className={styles.formControlSpan}>
                    Departamento
                    <span>*</span>
                  </div>
                  <div
                    className={
                      errors["campoe_40_412"]
                        ? styles.formControlSelectErr
                        : styles.formControlSelect
                    }
                  >
                    <select
                      name="campoe_40_412"
                      value={fieldsState.campoe_40_412}
                      onChange={(event) =>
                        handleChange("campoe_40_412", event.target.value)
                      }
                    >
                      <option value=""></option>
                      <option value="1" title="Education" order="0">
                        Education
                      </option>
                      <option value="2" title="Finance" order="1">
                        Finance
                      </option>
                      <option value="3" title="General Management" order="2">
                        General Management
                      </option>
                      <option value="4" title="IT" order="3">
                        IT
                      </option>
                      <option value="5" title="Journalist" order="4">
                        Journalist
                      </option>
                      <option value="6" title="Marketing" order="5">
                        Marketing
                      </option>
                      <option value="7" title="Operations" order="6">
                        Operations
                      </option>
                      <option value="8" title="Other" order="7">
                        Other
                      </option>
                      <option value="9" title="Human Resources" order="8">
                        Human Resources
                      </option>
                      <option value="10" title="Sales" order="9">
                        Sales
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div className={styles.formControlRow}>
                <div className={styles.formControl}>
                  <div className={styles.formControlSpan}>
                    Posição
                    <span>*</span>
                  </div>
                  <div
                    className={
                      errors["campoe_42_415"]
                        ? styles.formControlSelectErr
                        : styles.formControlSelect
                    }
                  >
                    <select
                      name="campoe_42_415"
                      value={fieldsState.campoe_42_415}
                      onChange={(event) =>
                        handleChange("campoe_42_415", event.target.value)
                      }
                    >
                      <option value=""></option>
                      <option value="1" title="Top Management" order="0">
                        Top Management
                      </option>
                      <option value="2" title="Director" order="1">
                        Director
                      </option>
                      <option value="3" title="Manager" order="2">
                        Manager
                      </option>
                      <option value="4" title="Consultant" order="3">
                        Consultant
                      </option>
                      <option value="5" title="Technical" order="4">
                        Technical
                      </option>
                      <option value="6" title="Administrative" order="5">
                        Administrative
                      </option>
                      <option value="7" title="Other" order="6">
                        Other
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div>
              
                <div className={styles.session__form__form__checkbox}>
                  <b>Pol&#237;tica de privacidade IDC</b>
                </div>

                <div className={styles.session__form__form__checkbox}>
                  <label className="container">
                    <span
                      className={
                        errors["campoe_23_413"]
                          ? styles.session__form__form__checkbox__labelErr
                          : styles.session__form__form__checkbox__label
                      }
                    >
                      Aceito que a IDC processe os meus dados pessoais de acordo
                      com a{" "}
                      <a
                        target="_black"
                        href="https://www.idc.com/about/privacy"
                      >
                        Política de Privacidade
                      </a>{" "}
                      da IDC.
                    </span>
                    <input
                      type="checkbox"
                      value="1"
                      name="campoe_23_413[]"
                      checked={fieldsState.campoe_23_413}
                      onChange={(event) =>
                        handleChange(
                          "campoe_23_413",
                          !fieldsState.campoe_23_413
                        )
                      }
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>

                <fieldset className={styles.fieldset}>
                  <legend>
                    <span class="labeling">Escolha uma Op&#231;&#227;o</span>
                    <span class="required_field"> *</span>
                    <span class="help_field"></span>
                  </legend>
                  <input
                    order="0"
                    visible="hidden"
                    useoutrolabel="Outro (qual?)"
                    easysync="campoe_17"
                    easylabel="easylabel"
                    id="campoe_17_424_0"
                    type="checkbox"
                    value="2"
                    title="FutureScape 2020 Inscri&#231;&#227;o"
                    name="campoe_17_424[]"
                    class="validate[optional]"
                  />
                  <label for="campoe_17_424_0" data-theme="grey">
                    FutureScape 2020 Inscri&#231;&#227;o
                  </label>
                  <input
                    order="1"
                    visible="hidden"
                    useoutrolabel="Outro (qual?)"
                    easysync="campoe_17"
                    easylabel="easylabel"
                    id="campoe_17_424_1"
                    type="checkbox"
                    value="3"
                    title="FutureScape 2020 - Sponsor"
                    name="campoe_17_424[]"
                    class="validate[optional]"
                  />
                  <label for="campoe_17_424_1" data-theme="grey">
                    FutureScape 2020 - Sponsor
                  </label>
                  <input
                    order="2"
                    visible="hidden"
                    useoutrolabel="Outro (qual?)"
                    easysync="campoe_17"
                    easylabel="easylabel"
                    id="campoe_17_424_2"
                    type="checkbox"
                    value="4"
                    title="Report 360"
                    name="campoe_17_424[]"
                    class="validate[optional]"
                  />
                  <label for="campoe_17_424_2" data-theme="grey">
                    Report 360
                  </label>
                  <input
                    order="3"
                    visible="hidden"
                    useoutrolabel="Outro (qual?)"
                    easysync="campoe_17"
                    easylabel="easylabel"
                    id="campoe_17_424_3"
                    type="checkbox"
                    value="5"
                    title="Report Cloud"
                    name="campoe_17_424[]"
                    class="validate[optional]"
                  />
                  <label for="campoe_17_424_3" data-theme="grey">
                    Report Cloud
                  </label>
                  <input
                    order="4"
                    visible="hidden"
                    useoutrolabel="Outro (qual?)"
                    easysync="campoe_17"
                    easylabel="easylabel"
                    id="campoe_17_424_4"
                    type="checkbox"
                    value="6"
                    title="Report Iot"
                    name="campoe_17_424[]"
                    class="validate[optional]"
                  />
                  <label for="campoe_17_424_4" data-theme="grey">
                    Report Iot
                  </label>
                  <input
                    order="5"
                    visible="hidden"
                    useoutrolabel="Outro (qual?)"
                    easysync="campoe_17"
                    easylabel="easylabel"
                    id="campoe_17_424_5"
                    type="checkbox"
                    value="7"
                    title="Report Big Data"
                    name="campoe_17_424[]"
                    class="validate[optional]"
                  />
                  <label for="campoe_17_424_5" data-theme="grey">
                    Report Big Data
                  </label>
                  <input
                    order="6"
                    visible="hidden"
                    useoutrolabel="Outro (qual?)"
                    easysync="campoe_17"
                    easylabel="easylabel"
                    id="campoe_17_424_6"
                    type="checkbox"
                    value="8"
                    title="Report Security"
                    name="campoe_17_424[]"
                    class="validate[optional]"
                  />
                  <label for="campoe_17_424_6" data-theme="grey">
                    Report Security
                  </label>
                  <input
                    order="7"
                    visible="hidden"
                    useoutrolabel="Outro (qual?)"
                    easysync="campoe_17"
                    easylabel="easylabel"
                    id="campoe_17_424_7"
                    type="checkbox"
                    value="9"
                    title="5 BEST PRACTICES FOR SELECTING SAAS AND CLOUD-ENABLED SOLUTIONS"
                    name="campoe_17_424[]"
                    class="validate[optional]"
                  />
                  <label for="campoe_17_424_7" data-theme="grey">
                    5 BEST PRACTICES FOR SELECTING SAAS AND CLOUD-ENABLED
                    SOLUTIONS
                  </label>
                  <input
                    order="8"
                    visible="hidden"
                    useoutrolabel="Outro (qual?)"
                    easysync="campoe_17"
                    easylabel="easylabel"
                    id="campoe_17_424_8"
                    type="checkbox"
                    value="10"
                    title="Parceiro - IDC Chief Data Officer 2020"
                    name="campoe_17_424[]"
                    class="validate[optional]"
                  />
                  <label for="campoe_17_424_8" data-theme="grey">
                    Parceiro - IDC Chief Data Officer 2020
                  </label>
                  <input
                    order="9"
                    visible="hidden"
                    useoutrolabel="Outro (qual?)"
                    easysync="campoe_17"
                    easylabel="easylabel"
                    id="campoe_17_424_9"
                    type="checkbox"
                    value="11"
                    title="Whitepaper Fujitsu"
                    name="campoe_17_424[]"
                    class="validate[optional]"
                  />
                  <label for="campoe_17_424_9" data-theme="grey">
                    Whitepaper Fujitsu
                  </label>
                  <input
                    order="10"
                    visible="hidden"
                    useoutrolabel="Outro (qual?)"
                    easysync="campoe_17"
                    easylabel="easylabel"
                    id="campoe_17_424_10"
                    type="checkbox"
                    value="12"
                    title="Report - Future of Trust:Security Market in Portugal2020 - 2024 "
                    name="campoe_17_424[]"
                    class="validate[optional]"
                  />
                  <label for="campoe_17_424_10" data-theme="grey">
                    Report - Future of Trust:Security Market in Portugal2020 -
                    2024
                  </label>
                  <input
                    order="11"
                    visible="hidden"
                    useoutrolabel="Outro (qual?)"
                    easysync="campoe_17"
                    easylabel="easylabel"
                    id="campoe_17_424_11"
                    type="checkbox"
                    value="13"
                    title="E-Book - E-Book - Um Guia para Escolher os Parceiros Certos para Acrescentar Valor ao seu Neg&#243;cio"
                    name="campoe_17_424[]"
                    class="validate[optional]"
                  />
                  <label for="campoe_17_424_11" data-theme="grey">
                    E-Book - E-Book - Um Guia para Escolher os Parceiros Certos
                    para Acrescentar Valor ao seu Neg&#243;cio
                  </label>
                  <input
                    order="12"
                    visible="hidden"
                    useoutrolabel="Outro (qual?)"
                    easysync="campoe_17"
                    easylabel="easylabel"
                    id="campoe_17_424_12"
                    type="checkbox"
                    value="14"
                    title="Future of Infrastructure: Cloud Computing in Portugal, 2020 - 2024"
                    name="campoe_17_424[]"
                    class="validate[optional]"
                  />
                  <label for="campoe_17_424_12" data-theme="grey">
                    Future of Infrastructure: Cloud Computing in Portugal, 2020
                    - 2024
                  </label>
                  <input
                    order="13"
                    visible="hidden"
                    useoutrolabel="Outro (qual?)"
                    easysync="campoe_17"
                    easylabel="easylabel"
                    id="campoe_17_424_13"
                    type="checkbox"
                    value="15"
                    title="Future of Infrastructure: Cloud Computing in Portugal, 2020 - 2024"
                    name="campoe_17_424[]"
                    class="validate[optional]"
                  />
                  <label for="campoe_17_424_13" data-theme="grey">
                    Future of Infrastructure: Cloud Computing in Portugal, 2020
                    - 2024
                  </label>
                  <input
                    order="14"
                    visible="hidden"
                    useoutrolabel="Outro (qual?)"
                    easysync="campoe_17"
                    easylabel="easylabel"
                    id="campoe_17_424_14"
                    type="checkbox"
                    value="16"
                    title="Future of Intelligence: Big Data, Analytics &amp; Artificial Intelligence in Portugal, 2020 - 2024"
                    name="campoe_17_424[]"
                    class="validate[optional]"
                  />
                  <label for="campoe_17_424_14" data-theme="grey">
                    Future of Intelligence: Big Data, Analytics &amp; Artificial
                    Intelligence in Portugal, 2020 - 2024
                  </label>
                  <input
                    order="15"
                    visible="hidden"
                    useoutrolabel="Outro (qual?)"
                    easysync="campoe_17"
                    easylabel="easylabel"
                    id="campoe_17_424_15"
                    type="checkbox"
                    value="17"
                    title="Future of Connectedeness IoT in Portugal, 2020 - 2024"
                    name="campoe_17_424[]"
                    class="validate[optional]"
                  />
                  <label for="campoe_17_424_15" data-theme="grey">
                    Future of Connectedeness IoT in Portugal, 2020 - 2024
                  </label>
                  <input
                    order="16"
                    visible="hidden"
                    useoutrolabel="Outro (qual?)"
                    easysync="campoe_17"
                    easylabel="easylabel"
                    id="campoe_17_424_16"
                    type="checkbox"
                    value="18"
                    title="Future of Enterprise: Digital &amp; IT Directions in Portugal, 2020 - 2024"
                    name="campoe_17_424[]"
                    class="validate[optional]"
                  />
                  <label for="campoe_17_424_16" data-theme="grey">
                    Future of Enterprise: Digital &amp; IT Directions in
                    Portugal, 2020 - 2024
                  </label>
                  <input
                    order="17"
                    checked="checked"
                    visible="visible"
                    useoutrolabel="Outro (qual?)"
                    easysync="campoe_17"
                    easylabel="easylabel"
                    id="campoe_17_424_17"
                    type="checkbox"
                    value="19"
                    title="Whitepaper Multicert"
                    name="campoe_17_424[]"
                    class="validate[optional]"
                  />
                  <label for="campoe_17_424_17" data-theme="grey">
                    Whitepaper Multicert
                  </label>
                </fieldset>

                <div className={styles.session__form__form__checkbox}>
                  <div
                    className={
                      errors["campoe_72_489"]
                        ? styles.session__form__form__checkbox__labelErr
                        : styles.session__form__form__checkbox__label
                    }
                  >
                    <label
                      className={styles.session__form__form__checkbox__label}
                    >
                      Concordo que a IDC possa partilhar meus dados de contato,
                      juntamente com outros dados pessoais que forneço neste
                      formulário de registo, com a Multicert, para os seguintes
                      fins: comunicação e ofertas comerciais. Cada instância de
                      comunicação será acompanhada de informações sobre a
                      retirada do seu consentimento. O seu consentimento poderá
                      ser retirado a qualquer momento entrando em contato com a
                      IDC ou com a Multicert. *
                    </label>
                  </div>
                  <br />
                  <div>
                    <label class="container">
                      <span>Sim</span>
                      <input
                        type="checkbox"
                        value="1"
                        title="Sim"
                        name="campoe_72_489[]"
                        checked={fieldsState.campoe_72_489_yes}
                        onChange={(event) => {
                          handleChange("campoe_72_489_yes", true);
                          handleChange("campoe_72_489_no", false);
                          handleChange("campoe_72_489", true);
                        }}
                      />
                      <span class="checkmark"></span>
                    </label>
                    <label class="container">
                      <span>Agora n&#227;o</span>

                      <input
                        type="checkbox"
                        value="3"
                        title="Agora n&#227;o"
                        name="campoe_72_489[]"
                        checked={fieldsState.campoe_72_489_no}
                        onChange={(event) => {
                          handleChange("campoe_72_489_yes", false);
                          handleChange("campoe_72_489_no", true);
                          handleChange("campoe_72_489", false);
                        }}
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
                {errors && (
                  <div className={styles.session__form__form__err}>
                    {errors["fname_406"] ||
                    errors["lname_407"] ||
                    errors["email_410"] ||
                    errors["ind_telemovel_405"] ||
                    errors["telemovel_405_numero"] ||
                    errors["easygoi_408"] ||
                    errors["easygoi_409"] ||
                    errors["campoe_18_411"] ||
                    errors["campoe_40_412"] ||
                    errors["campoe_42_415"] ? (
                      <div>
                        - Por favor, preencha todos os campos obrigatórios
                      </div>
                    ) : (
                      <span />
                    )}

                    {errors["campoe_23_413"] || errors["campoe_72_489"] ? (
                      <div>
                        - Para proceder deve aceitar todos os termos da Política
                        de privacidade IDC
                      </div>
                    ) : (
                      <span />
                    )}
                  </div>
                )}
                <div>
                  {loading ? (
                    <button disabled type="submit">
                      Muito obrigado, o documento será enviado para seu email.
                    </button>
                  ) : (
                    <button type="submit">Receber por email</button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </SessionBody>
      </Session>
    </div>
  );
}

export default HomeForm;

import React from "react";
import styles from "./home.module.scss";
import HomeHeader from './home-header'
import HomeServices from './home-servicos'
import HomeForm from './home-form'
import HomeVideo from './home-video'
function Home() {
  return (
    <div>
  <HomeHeader/>
  <HomeServices/>
  <HomeForm/>
  <HomeVideo/>
    </div>
  );
}
 /**
   
   
   */ 
export default Home;


import React from "react";
import { Switch, Router } from "react-router-dom";
import Route from './route'
import {history} from '../lib/url/index'
import Home from './home'
import './index.scss'
export default function Routes() {
  return <Router history={history}>
 <Switch>
  <Route path="/" exact component={Home} />
</Switch>
  </Router>;
}

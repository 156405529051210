import React from "react";
import styles from "./header.module.scss";
import cn from "classnames";
import logomarca from "../../assets/logomarca-white.png";
import logomarcaImg from "../../assets/logomarca-multicert-white.png";
import { CSSTransition } from 'react-transition-group';


export const Header = ({ className }) => {
  const [toggle, setToggle] = React.useState(false);
  return (
    <div className={cn(styles.container, className)}>
      <div className={cn(styles.wrapper, className)}>
        <div className={cn(styles.container__LogoAndMenu, className)}>
          <div className={cn(styles.container__LogoAndMenu__logo, className)}>
            <img src={logomarca}></img>
          </div>
          <div className={cn(styles.container__LogoAndMenu__menu, className)}>
            <ul>
              <li>
                <a href="#infographic">Infografia</a>
              </li>
              <li>
                <a href="#white-paper">White paper</a>
              </li>
              <li>
                <a href="#video">Vídeo</a>
              </li>
              <li>
                <a href="https://security.multicert.com/">Multicert</a>
              </li>
            </ul>
          </div>
        </div>
        <div className={cn(styles.container__ImageButtonToggle, className)}>
          <div className={cn(styles.container__imagem, className)}>
            <img src={logomarcaImg}></img>
          </div>
          <div className={cn(styles.buttonToggle, className)}>
            <button onClick={() => setToggle(!toggle)}>Menu</button>
          </div>
        </div>
      </div>
      <CSSTransition
        in={toggle}
        timeout={300}
        classNames="menu"
        unmountOnExit
  
      >
          <div className={cn(styles.menuToggle, className)}>
          <ul>
              <li>
                <a href="/home-services.png">Infografia</a>
              </li>
              <li>
                <a href="#white-paper">White paper</a>
              </li>
              <li>
                <a href="#video">Vídeo</a>
              </li>
              <li>
                <a href="https://security.multicert.com/">Multicert</a>
              </li>
            </ul>
        </div>
       </CSSTransition>
     
    </div>
  );
};

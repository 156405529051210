import React from 'react';
import Routes from './pages';

function App() {
  return (
   <Routes/>
  );
}

export default App;

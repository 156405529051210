import React from 'react';
import styles from './index.module.scss'
import {Header} from '../../modules/header'
import {Footer} from '../../modules/footer'
export default function DefaultLayout({ children }) {
  return (
    <div className={styles.container}>
    <Header/>
    <div  className={styles.wrapper}>{children}</div>
    <Footer/>
    </div>
  );
}

import React from "react";
import styles from "./home-video.module.scss";
import { Session, SessionBody } from "../../modules/session";
import YouTube from "react-youtube";

function HomeServicos() {
  const refVideo = React.useRef(null);
  const [height, setheight] = React.useState(300)
  React.useEffect(()=>{
    setheight(refVideo.current.offsetWidth<1200?300:'600')
    console.log(refVideo.current.offsetHeight)
  },[])
  
  const opts = {
    height: height,
    width: "100%",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };
  return (
    <div id="video"  ref={refVideo}>
      <Session className={styles.session}>
        <SessionBody className={styles.session__img} >
     
        <YouTube videoId="CbNgsRvx-6M" opts={opts} />
      
        </SessionBody>
      </Session>
    </div>
  );
}

export default HomeServicos;

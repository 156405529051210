export default {
  fieldsState: {
    fname_406: "",
    lname_407: "",
    email_410: "",
    ind_telemovel_405: "+351",
    telemovel_405_numero: "",
    easygoi_408: "",
    easygoi_409: "",
    campoe_18_411: "",
    campoe_40_412: "",
    campoe_42_415: "",
    campoe_23_413: '',
    campoe_72_489:'',
    campoe_72_489_yes:'',
    campoe_72_489_no:'',
  },
  constraints: {
    fname_406: {
      presence: { message: "Campo obrigatório" },
      length: {
        minimum: 3,
        tooShort: "^Digite seu nome corretamente",
      },
    },
    lname_407: {
      presence: { message: "Campo obrigatório" },
      length: {
        minimum: 3,
        tooShort: "^Digite seu apelido corretamente",
      },
    },
    email_410: {
      presence: { message: "Campo obrigatório" },
      email: { message: "^Digite um email válido" },
    },
    ind_telemovel_405: {
      presence: { message: "Campo obrigatório" },
      length: {
        minimum: 2,
        tooShort: "^Digite seu nome corretamente",
      },
    },
    telemovel_405_numero: {
      presence: { message: "Campo obrigatório" },
      length: {
        minimum: 5,
        tooShort:  "^Digite um numero de telemovél válido",
      },
    },
    easygoi_408: {
      presence: { message: "Campo obrigatório" },
      length: {
        minimum: 1,
        tooShort: "^Digite seu cargo",
      },
    },
    easygoi_409: {
      presence: { message: "Campo obrigatório" },
      length: {
        minimum: 1,
        tooShort: "^Digite o nome da sua empresa cargo",
      },
    },
    campoe_18_411: {
      presence: { message: "Campo obrigatório" },
      length: {
        minimum: 1,
        tooShort: "^Digite o nome da sua empresa cargo",
      },
    },
    campoe_40_412: {
      presence: { message: "Campo obrigatório" },
      length: {
        minimum: 1,
        tooShort: "^Digite o nome da sua empresa cargo",
      },
    },
    campoe_42_415: {
      presence: { message: "Campo obrigatório" },
      length: {
        minimum: 1,
        tooShort: "^Digite o nome da sua empresa cargo",
      },
    },
    campoe_23_413: {
      presence: {
        message: "^Campo obrigatório"
      },
      inclusion: {
        within: [true],
        message: "^Para proceder deve aceitar todos os termos da Política de privacidade IDC"
      }
    },
    campoe_72_489: {
      presence: {
        message: "^Escolha uma opção"
      },
      inclusion: {
        within: [true],
        message: "^Para proceder deve aceitar todos os termos da Política de privacidade IDC"
      }
    },
  },
};

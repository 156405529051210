import React from "react";
import styles from "./session.module.scss";
import cn from 'classnames'

export const SessionLayout = ({children, className}) => {
  return (
    <div>
     {children}
    </div>
  );
}
export const Session = ({children, className}) => {
  return (
    <div className={cn(styles.session, className)}>
     {children}
    </div>
  );
}
export const SessionHeader = ({children, className}) => {
  return (
    <div className={cn(styles.session__header, className)}>
     {children}
    </div>
  );
}
export const SessionBody = ({children, className}) => {
  return (
    <div className={cn(styles.session__body, className)}>
     {children}
    </div>
  );
}
export const SessionFooter = ({children, className}) => {
  return (
    <div className={cn(styles.session_button, className)}>
     {children}
    </div>
  );
}

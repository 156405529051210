import React from "react";
import { Modal } from "react-responsive-modal";
import ImageModal from "./assets/infographic_Multicert.png";

function HomeServicos({ open, onCloseModal }) {
  return (
    <div>
      <Modal open={open} onClose={onCloseModal}>
        <div>
          <img src={ImageModal} width="100%" />
        </div>
      </Modal>
    </div>
  );
}

export default HomeServicos;

import React from "react";
import styles from "./home-header.module.scss";
import { Session, SessionBody, SessionHeader } from "../../modules/session";

function HomeHeader() {
  return (
    <div>
      <Session className={styles.session}>
        <SessionBody className={styles.session__body}>
          <h1>
          Descubra a importância de um serviço SOC e a solução Multicert para a sua empresa
          </h1>
        </SessionBody>
      </Session>
    </div>
  );
}

export default HomeHeader;

import React from "react";
import styles from "./home-servicos.module.scss";
import { Session, SessionBody, SessionHeader } from "../../modules/session";
import Image from "./assets/home-services.png";
import ModalImage from "./home-servicos-modal";
function HomeServicos() {
  const [modalIsOpen, setIsOpen] = React.useState(false);

  const onOpenModal = () => {
    setIsOpen(true);
  };

  const onCloseModal = () => {
    setIsOpen(false);
  };

  return (
    <div id='infographic'>
      <Session className={styles.session}>
        <SessionBody className={styles.session__body}>
          <h2>
            Conheça a visão geral do Guia executivo para aquisição de serviços
            SOC
          </h2>
          <div className={styles.button}>
            <a onClick={() => onOpenModal()}>VER INFOGRAFIA COMPLETA</a>
          </div>
        </SessionBody>
        <SessionBody className={styles.session__img}>
          <img src={Image} />
        </SessionBody>
      </Session>
      <ModalImage open={modalIsOpen} onCloseModal={onCloseModal} />
    </div>
  );
}

export default HomeServicos;
